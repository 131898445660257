import { useImperativeHandle } from "react";

export const CalculateInch = (height, width) => {
  return {
    height: (Number(height) / 300).toFixed(1),
    width: (Number(width) / 300).toFixed(1),
  };
};

export const CalculateCm = (height, width) => {
  return {
    height: ((Number(height) / 300) * 2.54).toFixed(1),
    width: ((Number(width) / 300) * 2.54).toFixed(1),
  };
};

export const uploadedImageAreaInch = (height, width) => {
  return {
    area: (Number(height) / 180).toFixed(1) * (Number(width) / 180).toFixed(1),
  };
};

export const uploadedImageAreaCm = (height, width) => {
  return {
    area:
      ((Number(height) / 180) * 2.54).toFixed(1) *
      ((Number(width) / 180) * 2.54).toFixed(1),
  };
};

export const SquareInch = ({ height, width, measurement }) => {
  if (measurement === "cm") {
    return {
      area: ((Number(height) / 2.54) + 1) * ((Number(width) / 2.54) + 1),
    };
  } else {
    return {
      area: (Number(height) + 1) * (Number(width) + 1),
    };
  }
};

export const PrintOnlyPrice = (
  { height, width, measurement },
  selectedPaperType = {
    info: {
      filpaperTypePricename: 0.18,
      paperRollWidthInches: 44,
      paperRollWidthCm: 110,
      paperBasePrice: 18,
    },
  }
) => {
  if (measurement === "inch") {
    return (Number(height) + 1) *
      (Number(width) + 1) *
      selectedPaperType?.info?.filpaperTypePricename >
      selectedPaperType?.info?.paperBasePrice
      ? (Number(height) + 1) *
      (Number(width) + 1) *
      selectedPaperType?.info?.filpaperTypePricename
      : selectedPaperType?.info?.paperBasePrice;
  } else {
    return ((Number(height) + 2.54) / 2.54) *
      ((Number(width) + 2.54) / 2.54) *
      selectedPaperType?.info?.filpaperTypePricename >
      selectedPaperType?.info?.paperBasePrice
      ? ((Number(height) + 2.54) / 2.54) *
      ((Number(width) + 2.54) / 2.54) *
      selectedPaperType?.info?.filpaperTypePricename
      : selectedPaperType?.info?.paperBasePrice;
  }
};

export const FinalPrintOnlyPrice = (area, price) => {
  let FinalPrice;
  let discount = Number(price) * 0.08;
  if (area.area) {
    if (Number(area.area) < 576) {
      FinalPrice = price + 2;
      return FinalPrice;
    } else if (Number(area.area) > 576) {
      FinalPrice = price + 2 - discount;
      return FinalPrice;
    } else if (Number(area.area) > 864) {
      FinalPrice = price + 5 - discount;
      return FinalPrice;
    }
  }
};

export const TooLargePaperType = (
  { height, width, measurement },
  selectedPaperType = {
    info: {
      filpaperTypePricename: 0.18,
      paperRollWidthInches: 44,
      paperRollWidthCm: 110,
      paperBasePrice: 18,
    },
  }
) => {
  if (measurement === "inch") {
    if (
      Number(height) + 1 >
      Number(selectedPaperType?.info?.paperRollWidthInches) ||
      Number(width) + 1 > Number(selectedPaperType?.info?.paperRollWidthInches)
    ) {
      return "Too large Size for this paper type";
    }
  } else {
    if (
      Number(height + 2.54) >
      Number(selectedPaperType?.info?.paperRollWidthCm) ||
      Number(width + 2.54) > Number(selectedPaperType?.info?.paperRollWidthCm)
    ) {
      return "Too large Size for this paper type";
    }
  }
};

export const AspectRatioCalculate = (data) => {
  if (Object.keys(data).length !== 0) {
    let hcfInch;
    let inchHeight = Number(data.Inch.height) * 10;
    let inchWidth = Number(data.Inch.width) * 10;
    let cmHeight = Number(data.Cm.height) * 10;
    let cmWidth = Number(data.Cm.width) * 10;
    for (let i = 1; i <= inchHeight && i <= inchWidth; i++) {
      if (
        inchHeight !== NaN &&
        inchWidth !== NaN &&
        inchHeight % i == 0 &&
        inchWidth % i == 0
      ) {
        hcfInch = i;
      }
    }

    let AspectRatioHeightInch = inchHeight / hcfInch;
    let AspectRatioWidthInch = inchWidth / hcfInch;

    let hcfCm;
    for (let i = 1; i <= cmHeight && i <= cmWidth; i++) {
      if (cmHeight % i == 0 && cmWidth % i == 0) {
        hcfCm = i;
      }
    }
    let AspectRatioHeightCm = cmHeight / hcfCm;
    let AspectRatioWidthCm = cmWidth / hcfCm;

    return {
      Inch: { AspectRatioHeightInch, AspectRatioWidthInch },
      Cm: { AspectRatioHeightCm, AspectRatioWidthCm },
    };
  }
};

export const borderPrice = (finalDimensions, borders, selectedPaperType) => {
  if (selectedPaperType && finalDimensions?.measurement != "undefined") {

    let BorderLR = Number(borders?.Left) + Number(borders?.Right);
    let BorderTB = Number(borders?.Top) + Number(borders?.Bottom);
    let filpaperTypePricename = selectedPaperType?.info?.filpaperTypePricename;
    let paperBasePrice = selectedPaperType?.info?.paperBasePrice;

    let finalHeightWithBorder = Number(finalDimensions?.height) + BorderTB;
    let finalWidthWithBorder = Number(finalDimensions?.width) + BorderLR;

    let finalHeightWithoutBorder = 0;
    let finalWidthWithoutBorder = 0;

    if (finalDimensions?.measurement === "inch") {
      finalHeightWithoutBorder = Number(finalDimensions?.height) + 1;
      finalWidthWithoutBorder = Number(finalDimensions?.width) + 1;
    } else {
      finalHeightWithoutBorder = Number(finalDimensions?.height) + 2.54;
      finalWidthWithoutBorder = Number(finalDimensions?.width) + 2.54;
    }

    let heightAfterBorder = 0;
    let widthAfterBorder = 0;

    if (finalHeightWithoutBorder > finalHeightWithBorder) {
      heightAfterBorder = finalHeightWithoutBorder;
    } else {
      heightAfterBorder = finalHeightWithBorder;
    }

    if (finalWidthWithoutBorder > finalWidthWithBorder) {
      widthAfterBorder = finalWidthWithoutBorder;
    } else {
      widthAfterBorder = finalWidthWithBorder;
    }

    if (finalDimensions?.measurement === "inch") {

      if (
        (heightAfterBorder * widthAfterBorder) * filpaperTypePricename > paperBasePrice
      ) {
        return {
          prize: (
            (heightAfterBorder * widthAfterBorder) * filpaperTypePricename
          ).toFixed(2),
          heightAfterBorder: heightAfterBorder,
          widthAfterBorder: widthAfterBorder,
        };
      } else {
        return {
          prize: paperBasePrice,
          heightAfterBorder: heightAfterBorder,
          widthAfterBorder: widthAfterBorder,
        };
      }

    } else {

      if (
        ((heightAfterBorder / 2.54) * (widthAfterBorder / 2.54)) * filpaperTypePricename > paperBasePrice
      ) {
        return {
          prize: (
            ((heightAfterBorder / 2.54) * (widthAfterBorder / 2.54)) * filpaperTypePricename
          ).toFixed(2),
          heightAfterBorder: heightAfterBorder,
          widthAfterBorder: widthAfterBorder,
        };
      } else {
        return {
          prize: paperBasePrice,
          heightAfterBorder: heightAfterBorder,
          widthAfterBorder: widthAfterBorder,
        };
      }
    }

  }
};

export const FinalBorderPrice = (area, BorerPrice, printOnlyPrice) => {
  let finalPrice;
  let discount = Number(printOnlyPrice) * 0.08;

  if (area.area) {
    if (Number(area.area).toFixed(1) < 576) {
      finalPrice = Number(BorerPrice) + 2;
      return finalPrice;
    } else if (Number(area.area).toFixed(1) > 576) {
      finalPrice = Number(BorerPrice) + 2 - discount;
      return finalPrice;
    } else if (Number(area.area).toFixed(1) > 864) {
      finalPrice = Number(BorerPrice) + 5 - discount;
      return finalPrice;
    }
  }
};

export const validationForPaperError = (finalDimensions, borders, selectedPaperType) => {
  if (selectedPaperType && finalDimensions?.measurement != "undefined") {
    let paperTypeError = false;

    let finalWidthWithoutBorder = 0;

    let BorderLR = Number(borders?.Left) + Number(borders?.Right);
    let filpaperTypePricenameIn = selectedPaperType?.info?.paperRollWidthInches;
    let filpaperTypePricenameCm = selectedPaperType?.info?.paperRollWidthCm;

    if (finalDimensions?.measurement === "inch") {
      finalWidthWithoutBorder = Number(finalDimensions?.width) + BorderLR;
      if (finalWidthWithoutBorder > filpaperTypePricenameIn) {
        paperTypeError = 'Image dimension must fit within max roll width: ' + filpaperTypePricenameIn;
      }

    } else {
      finalWidthWithoutBorder = Number(finalDimensions?.width) + BorderLR;
      if (finalWidthWithoutBorder > filpaperTypePricenameCm) {
        paperTypeError = 'Image dimension must fit within max roll width: ' + filpaperTypePricenameCm;
      }
    }

    //console.log(paperTypeError, finalWidthWithoutBorder, filpaperTypePricenameIn)

    return paperTypeError;
  }
};

export const Ui = (height, width) => {
  let squareInch = Number(height) * Number(width);
  let squareFoot = squareInch / 144;
  let UI = Number(height) + Number(width);
  return UI;
};

export const matFinalUI = (mattings, frame, ply, ui) => {
  let matFinalDimensions;
  let unitedInch = Number(ui);
  let shadow;
  let matFinalUI;
  let matTop = Number(mattings?.Top);
  let matBottom = Number(mattings?.Bottom);
  let matLeft = Number(mattings?.Left);
  let matRight = Number(mattings?.Right);
  if (frame) {
    if (frame.frameOptions === "Shadow Box" && ply === "None") {
      shadow = 4;
    } else {
      shadow = 0;
    }
    if (ply === "None") {
      matFinalDimensions = 0;
    } else {
      matFinalDimensions = matTop + matBottom + matLeft + matRight + unitedInch;
    }

    if (unitedInch > matFinalDimensions) {
      matFinalUI = unitedInch + shadow;
    } else {
      matFinalUI = matFinalDimensions;
    }
  }
  return matFinalUI;
};

export const FrameUiRoundUp = (Ui) => {
  let ui = Number(Ui);
  if (ui <= 18) {
    return 18;
  } else if (ui <= 21) {
    return 21;
  } else if (ui <= 25) {
    return 25;
  } else if (ui <= 32) {
    return 32;
  } else if (ui <= 36) {
    return 36;
  } else if (ui <= 42) {
    return 42;
  } else if (ui <= 50) {
    return 50;
  } else if (ui <= 54) {
    return 54;
  } else if (ui <= 58) {
    return 58;
  } else if (ui <= 60) {
    return 60;
  } else if (ui <= 72) {
    return 72;
  } else if (ui <= 84) {
    return 84;
  } else if (ui <= 100) {
    return 100;
  } else if (ui > 100) {
    return 116;
  } else {
    return "You Broke It!";
  }
};

export const handmadeFrameRetail = (frameCode, matfinalUi, ply) => {
  let linearFoot = Math.ceil(Number(matfinalUi) / 12);
  let handmadeFrameCost;
  let handmadePaint;
  let handMadeJion;
  let handmadeSpacer;
  let handmadeSpacerYN;
  let HSMarkUp;
  let handmadeFrameRetail;

  if ((frameCode.Code === "HS-S")) {
    handmadeFrameCost = linearFoot * 3;
  } else if ((frameCode.Code === "HS-M")) {
    handmadeFrameCost = linearFoot * 4;
  } else if ((frameCode.Code === "HS-L")) {
    handmadeFrameCost = linearFoot * 5;
  } else {
    handmadeFrameCost = 0;
  }

  if (matfinalUi < 37) {
    handmadePaint = 100;
  } else if (matfinalUi <= 50) {
    handmadePaint = 150;
  } else if (matfinalUi > 50) {
    handmadePaint = 200;
  }

  if (matfinalUi <= 60) {
    handMadeJion = 50;
  } else if (matfinalUi > 60) {
    handMadeJion = 75;
  }

  if (matfinalUi <= 36) {
    handmadeSpacer = 20;
  } else if (matfinalUi <= 50) {
    handmadeSpacer = 30;
  } else if (matfinalUi > 50) {
    handmadeSpacer = 50;
  }

  if (ply === "None") {
    handmadeSpacerYN = handmadeSpacer;
  } else {
    handmadeSpacerYN = 0;
  }

  if (handmadeFrameCost <= 60) {
    HSMarkUp = 100;
  } else if (handmadeFrameCost <= 84) {
    HSMarkUp = 150;
  } else if (handmadeFrameCost > 85) {
    HSMarkUp = 200;
  } else {
    HSMarkUp = 0;
  }

  handmadeFrameRetail =
    handmadeFrameCost +
    handmadePaint +
    handMadeJion +
    handmadeSpacer +
    handmadeSpacerYN +
    HSMarkUp;

  return handmadeFrameRetail;
};

export const matFinalDimensionsCalulator = (mattings, ply, ui) => {
  let matFinalDimension;
  let matTop = Number(mattings?.Top);
  let matBottom = Number(mattings?.Bottom);
  let matLeft = Number(mattings?.Left);
  let matRight = Number(mattings?.Right);
  let unitedinch = Number(ui);
  if (ply === "None") {
    matFinalDimension = 0;
    return matFinalDimension;
  } else {
    matFinalDimension = matTop + matBottom + matLeft + matRight + unitedinch;
    return matFinalDimension;
  }
};

export const roundUpUi = (ply, Ui) => {
  //upar alay function toh augi eh
  let ui = Number(Ui);
  if (ply === "None") {
    return 0;
  } else {
    if (ui <= 18) {
      return 18;
    } else if (ui <= 24) {
      return 24;
    } else if (ui <= 32) {
      return 32;
    } else if (ui <= 36) {
      return 36;
    } else if (ui <= 42) {
      return 42;
    } else if (ui <= 44) {
      return 44;
    } else if (ui <= 50) {
      return 50;
    } else if (ui <= 54) {
      return 54;
    } else if (ui <= 60) {
      return 60;
    } else if (ui <= 72) {
      return 72;
    } else if (ui <= 78) {
      return 78;
    } else if (ui <= 84) {
      return 84;
    } else if (ui <= 90) {
      return 90;
    } else if (ui <= 100) {
      return 100;
    } else if (ui > 100) {
      return 100;
    } else {
      return "You Broke It!";
    }
  }
};

export const GlassRoundUp = (Ui) => {
  let ui = Number(Ui);
  if (ui <= 18) {
    return 18;
  } else if (ui <= 21) {
    return 21;
  } else if (ui <= 25) {
    return 25;
  } else if (ui <= 28) {
    return 28;
  } else if (ui <= 32) {
    return 32;
  } else if (ui <= 36) {
    return 36;
  } else if (ui <= 42) {
    return 42;
  } else if (ui <= 44) {
    return 44;
  } else if (ui <= 50) {
    return 50;
  } else if (ui <= 54) {
    return 54;
  } else if (ui <= 58) {
    return 58;
  } else if (ui <= 60) {
    return 60;
  } else if (ui <= 62) {
    return 62;
  } else if (ui <= 66) {
    return 66;
  } else if (ui <= 70) {
    return 70;
  } else if (ui <= 72) {
    return 72;
  } else if (ui <= 84) {
    return 84;
  } else if (ui <= 100) {
    return 100;
  } else if (ui > 100) {
    return 116;
  } else {
    return "You Broke It!";
  }
};

export const BackingMaterialCalculator = (Ui) => {
  let ui = Number(Ui);
  if (ui) {
    if (ui <= 36) {
      return 12;
    } else if (ui <= 60) {
      return 16;
    } else if (ui > 60) {
      return 36;
    }
  }
};

export const spacerSizeFeeCalculator = (Ui) => {
  let ui = Number(Ui);
  if (ui) {
    if (ui <= 60) {
      return 0;
    } else if (ui <= 78) {
      return 20;
    } else if (ui > 78) {
      return 60;
    } else {
      return 0;
    }
  }
};

export const MouldingTypePrice = (height, width, moulding, prize) => {
  if (moulding) {
    var height = Number(height)
    var width = Number(width)
    let squarefoot = Math.ceil((height * width) / 144);
    let laminate50 = (squarefoot * 5.8) * 1.05;
    let laminate70 = (squarefoot * 7.353) * 1.05;
    let either = (height > 50 || width > 50) ? Number(laminate70.toFixed(2)) : Number(laminate50.toFixed(2))
    let dibond = (squarefoot * 15.5) * 1.05
    let rails = (Math.ceil((((height - 6) * 2) + (width - 6) * 2) / 12)) * 9.25 * 1.05
    let zbar = Math.ceil((width - 9) / 12) * 3.5 * 1.05
    let railsZbar = rails + zbar
    let printCost = (height * width) * 0.13
    let processingFee = 10.5
    let price;

    if (moulding === "1/2 Ultra Mount") {
      price = squarefoot * 13.9 + 10;
      return Number(price);
    } else if (moulding === "3/16 Ultra Mount") {
      price = squarefoot * 10.45 + 10;
      return Number(price);
    } else if (moulding === "3/16 Archival Foam Board") {
      price = height * width * 0.075;
      return Number(price);
    } else if (moulding === "Alupanel") {
      var total = either + dibond + railsZbar + processingFee;
      price = (total + printCost) < 158 ? 158 : (total + printCost)
      return Number(price);
    } else {
      return Number(0);
    }

  }
};

export const AlupanelPrice = (height, width, moulding, prize) => {
  if (moulding) {
    // console.log(height, width);
    let squarefoot = Math.ceil((Number(height) * Number(width)) / 144);

    // console.log(height, width, squarefoot);
    let price;

    if (moulding === "Alupanel") {
      let mouldingPrice;
      let laminate;
      let dibond;
      let railsAndZbar;
      let rails;
      let Zbar;
      let PrintCostAlupanel;
      if (Number(height) > 50 || Number(width) > 50) {
        laminate = squarefoot * 7.358;
      } else {
        laminate = squarefoot * 5.8;
      }
      dibond = squarefoot * 14 + 10;
      if (
        Math.ceil(((Number(height) - 6) * 2 + (Number(width) - 6) * 2) / 12) *
        9.25 <
        0
      ) {
        rails = 0;
      } else {
        rails =
          Math.ceil(((Number(height) - 6) * 2 + (Number(width) - 6) * 2) / 12) *
          9.25;
      }

      if (Math.ceil(Number(width) - 9) / 12 < 0) {
        Zbar = 0;

      } else {
        Zbar = Math.ceil(Number(width) - 9) / 12;
      }


      railsAndZbar = rails + Zbar;
      PrintCostAlupanel = Number(height) * Number(width) * 0.13;

      mouldingPrice = laminate + dibond + railsAndZbar;

      return mouldingPrice
    }
  }
};

export const numberToPixels = ({ height, width, measurement }) => {
  if (measurement === "inch") {
    return {
      height: (Number(height) * 300).toFixed(1),
      width: (Number(width) * 300).toFixed(1),
    };
  } else {
    return {
      height: ((Number(height) * 300) / 2.54).toFixed(1),
      width: ((Number(width) * 300) / 2.54).toFixed(1),
    };
  }
};
